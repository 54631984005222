/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from "styled-components";
import { Link as LinkStandard, useHistory, matchPath } from "react-router-dom";
import { resolveProps, fontable } from "../../styles/styledComponentsMixins";

const Link = styled(
	({
		noScroll = false,
		closeModal,
		onClick,
		refresh,
		top,
		iblock,
		block,
		underline,
		asLink,
		noColor,
		scrollForce,
		toRight,
		noColorVisited,
		...props
	}) => {
		const history = useHistory();
		const reload = () => {
			const isMain = matchPath(location?.pathname, {
				path: "/",
				exact: true,
			});

			if (!isMain) {
				history.push("/");
			} else {
				history.replace("/");
			}
			history.location.state = {
				...history.location.state,
				reload: true,
			};
		};

		return (
			<LinkStandard
				{...resolveProps(props, fontable)}
				onClick={(event) => {
					if (!noScroll) {
						setTimeout(() => {
							window.scrollTo(0, 0);
						}, 0);
					}

					if (typeof closeModal === "function") {
						document.body.style.overflow = "unset";
						document.body.style.top = "";
					}

					if (history && refresh) {
						event.preventDefault();
						reload();
						return;
					}

					if (onClick) {
						onClick(event);
					}
				}}
			/>
		);
	}
)`
	color: ${({ theme: { colors } }) => colors.primary};
	&:visited {
		color: ${({ theme: { colors } }) => colors.primary};
	}

	${({ noColorVisited }) =>
		noColorVisited &&
		css`
			&:visited {
				color: inherit;
			}
		`}

	${({ noColor }) =>
		noColor &&
		css`
			color: ${({ theme: { colors } }) => colors.black};
		`}

	${fontable.css}
	${({ iblock }) =>
		iblock &&
		css`
			display: inline-block;
		`}
	${({ block }) =>
		block &&
		css`
			display: block;
		`}
	${({ underline }) =>
		underline &&
		css`
			&:hover {
				text-decoration: underline !important;

				div {
					span {
						text-decoration: underline !important;
					}
				}

				span {
					text-decoration: underline !important;
				}
			}
		`}
`;

Link.displayName = "Link";

export default Link;
